<template>
  <header class="global-header">
    <div class="news">
      <div class="carousel">
        <p>Check our partner: <a href="https://bichonboutique.com?sca_ref=3251555.GRcbcuwfHb&utm_source=timhockey&utm_medium=cpc&utm_campaign=affiliate" target="_blank">Bichon Boutique</a> - Specialized online dog boutique. Use code CHALLENGE10 for 10% off your order.</p>
      </div>
    </div>
    <div class="primary-header">
        <div class="logo">
            <a href="/">
                <p>Tim's</p>
                <p>Hockey Challenge Helper</p>
            </a>
        </div>

        <div class="display-flex item-center">
            <div class="theme-switcher">
                <span class="material-icons dark-mode theme-switcher__icon" :class="{' theme-switcher__icon_active' : !darkMode}" @click="toggleTheme">dark_mode</span>
                <span class="material-icons light-mode theme-switcher__icon" :class="{' theme-switcher__icon_active' : darkMode}" @click="toggleTheme">wb_sunny</span>
            </div>
            <div class="button" @click="toggleMenu">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </div>
        </div>
        
        <nav class="navigation" :class="[menuActive ? 'showNav' : '']">
            <span class="material-icons material-symbols-outlined" @click="toggleMenu">
                close
            </span>
            <ul>
                <li>
                    <router-link :to="{ name: 'Home' }" @click="toggleMenu">Home</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'Scoreboard' }" @click="toggleMenu">Scoreboard</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'SupportUs' }" @click="toggleMenu">Support Us</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'Updates' }" @click="toggleMenu">Updates</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'Feedback' }" @click="toggleMenu">Feedback</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'Faq' }" @click="toggleMenu">FAQ</router-link>
                </li>
            </ul>
        </nav>
    </div>
  </header>
</template>

<script>
import { onMounted, ref } from 'vue'

export default {
    name: 'Header',
    setup() {
        const darkMode = ref(false)
        const menuActive = ref(false)

        const toggleMenu = () => {
            menuActive.value = !menuActive.value
        }

        const toggleTheme = () => {
            if(!darkMode.value) {
                darkMode.value = true
                document.querySelector('body').classList.add('dark-theme')
                localStorage.setItem('theme', 'dark')
            }
            else {
                darkMode.value = false
                document.querySelector('body').classList.remove('dark-theme')
                localStorage.removeItem('theme')
            }
        }

        onMounted(() => {
            if(localStorage.getItem('theme') === 'dark') {
                darkMode.value = true
                document.querySelector('body').classList.add('dark-theme')
            }
        })

        return { darkMode, toggleTheme, menuActive, toggleMenu }
    }
}
</script>

<style scoped>
.global-header {
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%), 0 2px 2px rgb(0 0 0 / 6%), 0 0 2px rgb(0 0 0 / 7%);
}
.primary-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}
.logo p {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    padding-right: 5px;
    color: var(--grey-6);
}

.primary-header .material-icons {
    vertical-align: middle;
    cursor: pointer;
}
.news {
  background-color: var(--grey-1);
}
.news .carousel {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 0.3rem 20px;
    box-sizing: border-box;
}
.news p {
    font-size: 12px;
    font-weight: 500;
}
.news a {
    text-decoration: underline;
}
.light-mode {
    color: var(--white);
}
.dark-mode {
    color: var(--grey-5);
}
.theme-switcher {
    display: flex;
}
.theme-switcher__icon {
    display: none;
}
.theme-switcher__icon.theme-switcher__icon_active {
    display: inline-block;
}
.navigation {
    position: fixed;
    height: 100%;
    width: 100%;
    top: -100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--grey-4);
    z-index: 99999;
}
.navigation {
    text-align: center;
}
.navigation li span {
  font-size: 12px;
    font-weight: 600;
    background-color: var(--red-1);
    color: var(--red-6);
    border-radius: 4px;
    padding: 1px 5px;
    margin-right: 1rem;
    display: inline-block;
    vertical-align: middle;
}
.navigation .material-icons {
    color: var(--white);
    font-size: 3rem;
    padding-top: 50px;
}
.navigation ul {
    margin: 2rem 0;
}
.navigation a {
    color: var(--white);
    font-size: 1.5rem;
    font-weight: 600;
    display: block;
    padding: 10px 0;
}
.navigation .router-link-exact-active {
    color: var(--red-6);
}
.navigation a:hover {
    opacity: 0.7;
}
.primary-header .button {
    width: 27px;
    cursor: pointer;
    margin-left: 15px;
    position: relative;
}
/* .primary-header .button:before {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: var(--red-5);
  top: -4px;
  left: -5px;
} */
.icon-bar {
  display: block;
  width: 100%;
  height: 3px;
  background: var(--grey-6);
}
.icon-bar + .icon-bar {
  margin-top: 5px;
}
@keyframes showNav {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

@-webkit-keyframes showNav {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

@-moz-keyframes showNav {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

@-o-keyframes showNav {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

.showNav {
  -webkit-animation: showNav 0.3s ease forwards;
  -moz-animation: showNav 0.3s ease forwards;
  -o-animation: showNav 0.3s ease forwards;
  animation: showNav 0.3s ease forwards;
}

@keyframes hideNav {
  from {
    top: 0;
  }
  to {
    top: -100%;
  }
}

@-webkit-keyframes hideNav {
  from {
    top: 0;
  }
  to {
    top: -100%;
  }
}

@-moz-keyframes hideNav {
  from {
    top: 0;
  }
  to {
    top: -100%;
  }
}

@-o-keyframes hideNav {
  from {
    top: 0;
  }
  to {
    top: -100%;
  }
}

.hideNav {
  -webkit-animation: hideNav 1s ease forwards;
  -moz-animation: hideNav 1s ease forwards;
  -o-animation: hideNav 1s ease forwards;
  animation: hideNav 1s ease forwards;
}

.hidden {
  display: none;
}
</style>