<template>
    <main class="container body-content">
        <div>
    <div class="text-center" v-if="`${getErrorStatus}` === ''">
      <Spinner/>
    </div>
    <div class="site-status text-center" v-else-if="`${getErrorStatus}` === '502'">
        <span class="material-icons error-sign">error</span>
        <p class="error-message">Error retrieving data from Tim Horton's, please try again later.</p>
    </div>
    <div class="site-status text-center" v-else-if="`${getErrorStatus}` === '404'">
        <span class="material-icons error-sign">error</span>
        <p class="error-message">All the games have started! Check again tomorrow.</p>
    </div>
    <div class="site-status text-center" v-else-if="`${getErrorStatus}` === '405'">
        <span class="material-icons error-sign">error</span>
        <p class="error-message">Stats are still being processed, please try again in a few seconds.</p>
    </div>
    <section class="homepage-stats" v-else-if="`${getErrorStatus}` === '200' || `${getErrorStatus}` === '400'">
      <div class="demo-message display-flex justify-center text-center">
        <span>
          <div>This is the demo site for <a href='https://hockeychallengehelper.com'>Tim's Hockey Challenge Helper</a>.</div>
          <div>The Playoffs button and Season dropdown don't work in the demo!</div>
        </span>
      </div>
        <FilterNavbar />
        <Spinner v-if="getSpinnerState" />
        <div class="site-status text-center" v-else-if="`${getErrorStatus}` === '400'">
            <span class="material-icons error-sign">error</span>
            <p class="error-message">There are no stats available!</p>
        </div>
        <div class="content" v-else>
            <Warnings />
            <PlayerLists v-if="`${getDataScope}` === 'overall'" />
            <Trend v-else />
        </div>
    </section>
    <div class="site-status text-center" v-else-if="`${getErrorStatus}` === '403' || `${getErrorStatus}` === '525'">
        <span class="material-icons error-sign">error</span>
        <p class="error-message">Our server is currently overloaded!  Please try again in a couple of minutes.</p>
    </div>
              <div class="site-status text-center" v-else>
        <span class="material-icons error-sign">error</span>
        <p class="error-message">Something went wrong!  Please try again later.</p>
    </div>
</div>
      <Legend />
    </main>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import Spinner from "@/components/Spinner";
import FilterNavbar from "@/components/FilterNavbar";
import Warnings from '@/components/Warnings';
import PlayerLists from "@/components/PlayerLists";
import Trend from "@/components/Trend";
import Legend from "@/components/Legend";

export default {
  name: "Home",
  components: {
    Spinner,
    FilterNavbar,
    Warnings,
    PlayerLists,
    Trend,
    Legend
    },
    meta: {
        title : "Tim Horton's Hockey Challenge Helper"
    },
  setup() {
    const store = useStore();

    // call getpicks function in actions in vuex
    store.dispatch('getPicks')
    // call getGameLogs function in actions in vuex
    store.dispatch('getGameLogs')

    const getErrorStatus = computed(() => {
      return store.state.error
    })

    const getSpinnerState = computed(() => {
      return store.state.spinner
    })

    const getDataScope = computed(() => {
      return store.state.dataScope
    })

    return { getSpinnerState, getDataScope, getErrorStatus }
  }
}
</script>
<style scoped>
.disclaimer {
  text-align: center;
  font-size: 14px;
  margin: 10px 0;
}
.error-sign {
  font-size: 3rem;
}
.error-message {
  font-size: 1.2rem;
  font-weight: 600;
}
.site-status {
  margin-top: 2rem;
}
.demo-message {
  margin-bottom: 1.5rem;
}
.demo-message span {
  background-color: #bde6bd;
    border: 1px solid #92be92;
    padding: 3px;
    font-weight: 500;
}
.demo-message a {
  text-decoration: underline;
}
</style>