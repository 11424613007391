<template>
    <footer>
        <div class="container">
            <div class="text-center">
                <a href="#" class="to-top">Back to top</a>
                <p class="copy-right">&copy; Tim's Hockey Challenge Helper</p>
                <p class="disclaimer">**This website is not affilated with Tim Hortons**</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
    footer {
        padding: 1rem 0;
        background-color: var(--grey-1);
    }
    footer p {
        margin-top: 5px;
    }
    .disclaimer {
        text-align: center;
        font-size: 14px;
    }
    /* .dark-theme footer {
        background-color: var(--grey-6);
        border-top: 1px solid var(--white);
        color: var(--white);
    } */
    footer .to-top {
        text-decoration: underline;
    }
</style>