<template>
    <main class="container body-content">
		<section class="faq">
			<div class="section-title display-flex">
				<h1 class="section-title__label">FAQ</h1>
			</div>
			<div class="content">
				<Accordion>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">Is this site affiliated with Tim Hortons?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>No.  This is built by fans of the Tim Horton’s NHL Hockey Challenge.  There is no affiliation with Tim Hortons.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">What is Tim's NHL Hockey Challenge?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>The Tim's NHL Hockey Challenge is a game in the Tim Hortons app where you pick three players you think will score in that day's NHL games. If you pick correctly you win Tims Rewards points! The amount you win depends on the number of correct picks you made that day. As of the 2022-2023 NHL season the rewards are:</p>
							<ul>
								<li>1 correct pick = 5 points or an offer</li>
								<li>2 correct picks = 10 points</li>
								<li>3 correct picks = 50 points</li>
							</ul>
							<p>If you get seven days in a row correct you get seven days of free coffee or tea. There is a monthly challenge where you can compete to be in the top five to win extra prizes and the player with the most correct picks at the end of the regular season wins a Hyundai IONIQ 5.</p>

							<p>For more information go to the Tim's NHL Hockey Challenge website.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">Do shootout goals count as goals?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>Unfortunately during the 2021-2022 season shootout goals stopped counting as a successful pick.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">The Tim's NHL Hockey Challenge is not working!</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>If the game is down or is trying to get you to set your display name after you've already set it you'll need to wait until the game is fixed. If the game is down the whole day Tim Hortons will skip that day so no one will lose their streaks.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">Why does Tim's NHL Hockey Challenge say Check Back Tomorrow?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>If there are no NHL games on the day there will be no players to pick. Streaks will not be broken and can continue the next day.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">Why did the players change?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>When games start and there are still games to play the player list refreshes removing the players whose games have started.  Some players who are still yet to play may be removed or added too.</p>
							<p>You can use this to your advantage if you think better players will become available later in the day.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">Can I change a pick if the player is out of the lineup?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>Unfortunately once you’ve locked in your picks you cannot change them for any reason.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">Can I report issues with the Tim Horton’s Hockey Challenge game itself?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>The best way to report any problems with Tim Horton’s Hockey Challenge is to contact Tim Hortons through the Tim Hortons app or on social media.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">I got one pick right but I didn't get any points!</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>Check your Tim Hortons offers. In the last week of a challenge if you picked one player correctly you will get an offer instead of the five points. Two or three correct picks still award their respective points.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">What's a Tim Hortons offer?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>An offer shows up on your app as a coupon you can activate to add to an order in the mobile app or in person if you scan your QR code.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">How do I redeem an offer that I won?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>On the Tim Hortons App there is a section called Rewards & Offers.  Any offers won in Tim Horton’s Hockey Challenge, either from a single correct pick during offers week or free coffee/tea from a seven day streak will appear here.</p>
							<p>Click the Activate button and you can use it when you order.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">How do I use my seven days of free coffee or tea?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>Find the free coffee/tea offer in your Rewards & Offers section in the Tim Hortons App.  Click the Activate button and use it when ordering either through mobile order or by scanning your card.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">Where can I find the official rules and regulations for Tim's NHL Hockey Challenge?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>For full challenge rules and entry details, visit Tim's Official Rules & Regulations.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">What is the Tim's Hockey Challenge Helper?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>The Tim's Hockey Challenge Helper was built to help players make the best picks and get the most points when playing Tim's NHL Hockey Challenge.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">What happened to <span>https://timmies.zorbane.com?</span></h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>The original Tim Horton's Hockey Challenge Helper was built as a short term project but quickly grew beyond its original scope. It was also a front-end only app (as in all the work is done on the browser) so came with technical limitations. This new site does not have those limits.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">When does the site update?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>The site updates every time Tim Hortons Hockey Challenge updates.  That would be after a set of games start (ie 7:00pm ET) or at midnight EST.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">How do I use the Tim's Hockey Challenge Helper?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>The site displays in real time the players available in each list, plus their stats. The columns are sortable and you can look at the current year (default) or previous year's stats along with picking regular season or playoff stats. If you choose to view stats by “Trend” you can view the player stats by their last X games.</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">How do I make the best picks?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>Everyone has their own strategy. It may be players who scored the most goals, players who are playing teams that allow a lot of goals etc. Try to figure one out for yourself!</p>
						</template>
					</AccordionItem>
					<AccordionItem>
						<template v-slot:accordion-trigger>
							<h3 class="accordion__item-header">Can I view my standings on the Hockey Challenge Helper website?</h3>
							<span class="material-icons">expand_more</span>
						</template>
						<template v-slot:accordion-content>
							<p>Unfortunately it is not possible to find a specific user's game stats.</p>
						</template>
					</AccordionItem>
				</Accordion>
			</div>
		</section>
    </main>
</template>

<script>
import Accordion from "../components/Accordion";
import AccordionItem from "../components/AccordionItem";

export default {
  name: "Faq",
  components: {
    Accordion,
    AccordionItem,
  },
    meta: {
        title : "Tim Horton's Hockey Challenge Helper - FAQ"
    },
};
</script>

<style scoped>
.accordion__item-header {
    font-size: 16px;
    color: var(--grey-5);
    font-weight: 700;
}
.accordion__item-body ul {
    list-style-type: disc;
    list-style-position: inside;
  }
.page-link {
    text-decoration: underline;
    color: var(--blue);
}
.accordion__trigger .material-icons {
    width: 24px;
    height: 24px;
    transform: rotate(0deg);
    transition: transform .2s linear;
  }
  .accordion__trigger.accordion__trigger_active .material-icons {
    transform: rotate(180deg);
    transition: transform .2s linear;
  }

  .dark-theme .accordion__item-header {
    color: var(--white);
  }
</style>
  