<template>
        <td class="sticky-col text-left">
            <div class="player-name player-link">
                <img :src= "`teamLogos/${player.team}.png`" :alt="`${player.team} logo`" class="vertical-middle">
                <a class="vertical-middle" :href="[ url ] + player.nhlPlayerId" target="_blank">{{ getPlayerName }}</a>
            </div>
        </td>
        <td class="col-1 text-center">
            <div>{{ player.position }}</div>
        </td>
        <td class="col-2 text-center">
            <div>{{ player.gamesPlayed }}</div>
        </td>
        <td class="col-3 text-center">
            <div>{{ player.goals }}</div>
        </td>
        <td class="col-4 text-center">
            <div>{{ showTwoDecimals(player.shotsPerGame) }}</div>
        </td>
        <td class="col-5 text-center">
            <div>{{ showOneDecimals(player.shootingPercentage) }}</div>
        </td>
        <td class="col-6 text-center">
            <div>{{ player.powerPlayTimeOnIcePerGame }}</div>
        </td>
        <td class="col-7 text-center">
            <div>{{ player.timeOnIcePerGame }}</div>
        </td>
        <td class="col-8 text-center">
            <div>{{ showTwoDecimals(player.goalsPerGame) }}</div>
        </td>
        <td class="col-9 text-center">
            <div>{{ player.homeAway }}</div>
        </td>
        <td class="col-10 text-center">
            <div class="opponent-team">
                <img :src= "`teamLogos/${player.opponentTeam}.png`" :alt="`${player.opponentTeam} logo`" class="vertical-middle">
                <span class="vertical-middle">{{ showTwoDecimals(player.opponentGAA) }}</span>
            </div>
        </td>
</template>

<script>
import { computed } from 'vue'

export default {
    name: 'Player',
    props: ['player'],
    setup(props) {
        const url = "https://www.nhl.com/player/";

        const getPlayerName = computed(() => {
            let firstName = props.player.firstName
            let lastName = props.player.lastName
            let firstCharactor = firstName.substring(0, 1)

            let fullName = firstCharactor + ". " + lastName

            return fullName
        })

        const showTwoDecimals = (number) => {
            let toFixedNumber;

            if(number === 'N/A') {
                toFixedNumber = 'N/A'
            }
            else {
                toFixedNumber = parseFloat(number).toFixed(2)
            }

            return toFixedNumber
        }

        const showOneDecimals = (number) => {
            let toFixedNumber = parseFloat(number).toFixed(1)

            return toFixedNumber
        }

        return { url, getPlayerName, showTwoDecimals, showOneDecimals }
    }
}
</script>

<style>
    .players .player-link {
        display: inline-block;
    }
    .players img {
        width: 22px;
        padding-right: 5px;
    }
    .players .sticky-col a {
        color: var(--blue-3);
    }
    .players .sticky-col a:hover {
        color: var(--dark-blue);
    }
    .players .col-1 {
        border-left: 0;
    }
</style>