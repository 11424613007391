<template>
  <main class="container body-content">
      <section class="updates">
          <div class="section-title display-flex">
              <h1 class="section-title__label">Updates</h1>
          </div>
          <section class="content">
              <section>
                  <article class="blogs">
                      <h2>Minor Update</h2>
                      <time class="date" datetime="2023-02-21">February 21, 2023</time>
                      <div class="article-content">
                          <ul>
                              <li>More stability fixes.</li>
                              <li>Added email to feedback form because people ask questions but don't leave their email so we can't reply :(</li>
                          </ul>
                      </div>
                  </article>
              </section>
              <section>
                  <article class="blogs">
                      <h2>Scoreboard</h2>
                      <time class="date" datetime="2023-02-06">February 6, 2023</time>
                      <div class="article-content">
                          <ul>
                              <li>New Scoreboard Page.  Live view of games and picks who scored.</li>
                              <li>Upgraded server.  Will hopefully resolve the 'overloaded server' issue.  (update: still having problems with this)</li>
                              <li>More player warnings (ie sent to minors/AHL).</li>
                              <li>Nicer error handling.</li>
                              <li>Various performance improvements.</li>
                              <li>Various bug fixes.</li>
                          </ul>
                      </div>
                  </article>
              </section>
              <section>
                  <article class="blogs">
                      <h2>Dark mode</h2>
                      <time class="date" datetime="2022-12-20">December 20, 2022</time>
                      <div class="article-content">
                          <ul>
                              <li>Dark Mode</li>
                              <li>Crossmatch Player and team to warn of players who are not on the Tim Horton's team</li>
                              <li>Added more content to FAQ.</li>
                          </ul>
                      </div>
                  </article>
              </section>
              <section>
                  <article class="blogs">
                      <h2>Updates</h2>
                      <time class="date" datetime="2022-12-13">December 13, 2022</time>
                      <div class="article-content">
                          <ul>
                              <li>Changed highlight for injured players to make it accessible for people who are colourblind.</li>
                              <li>Fixed game logs not loading immediately after new picks are available.</li>
                              <li>Fixed long time out when picking invalid stats period.</li>
                          </ul>
                      </div>
                  </article>
              </section>
              <section class="road-map">
                  <h2>Major Feature Roadmap</h2>
                  <em>Thanks for all your feedback and support. Please remember this is a hobby project so there will be long gaps between updates!</em>
                  <div class="article-content">
                      <ul>
                          <li>
                              History
                              <ul>
                                  <li>Previous available players.</li>
                              </ul>
                          </li>
                      </ul>
                  </div>
              </section>
          </section>
      </section>
  </main>
</template>

<script>

export default {
  name: "Updates",
    meta: {
        title : "Tim Horton's Hockey Challenge Helper - Updates"
    },
};
</script>

<style scoped>
.updates h2 {
  font-size: 1.2rem;
  color: var(--grey-6);
}
.updates .date {
  font-size: 0.9rem;
  color: var(--grey-4);
}
.updates .article-content {
  margin-top: 0.5rem;
}
.updates ul li {
  margin-left: 2rem;
}
.updates .blogs ul li {
  list-style: disc;
}
.updates .road-map ul li {
  list-style: decimal;
}
.updates .road-map ul ul li {
  list-style: lower-latin;
  margin-left: 2rem;
}

.dark-theme .updates h2, .dark-theme em {
  color: var(--white);
}
.dark-theme .updates .date {
  color: var(--grey-2);
}
.dark-theme .updates .article-content {
  color: #dedede;
}
</style>